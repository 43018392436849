<!-- System: STA
    Purpose: In active projects user can see his all active
          projects.
-->
<template>
  <!-- Projects Card  -->
  <v-card class="mx-auto my-auto project-boxes" width="250" elevation="10">
    <!-- Projects Image  -->
    <img
      :src="base_url + 'storage/' + project.logo"
      class="img-fluid proj-img mt-2"
      @error="
        $event.target.src = require('../../assets/images/dashboard_icons/no_image.svg')
      "
      height="150"
      width="200"
    />
    <!-- tooltip For Project Name  -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <h3
          class="text-center mt-2"
          v-bind="attrs"
          v-on="on"
          v-if="project.project_name.length <= 20"
        >
          {{ project.project_name }}
        </h3>
        <h3 class="text-center mt-2" v-bind="attrs" v-on="on" v-else>
          {{ project.project_name.slice(0, 15) + ".." }}
        </h3>
      </template>
      <span>{{ project.project_name }}</span>
    </v-tooltip>

    <!-- Project Actions -->
    <v-card-actions
      class="d-flex justify-center py-3"
      v-if="selectedCompanyUserStatus !== 'employee' && showPermission"
    >
      <v-btn text @click.stop="deleteProject(project)">
        <v-icon color="primary">mdi-delete-forever</v-icon>
      </v-btn>
      <div class="mx-3" @click="update_project_data(project.id)">
        <v-btn text>
          <v-icon color="primary">mdi-account-edit</v-icon>
        </v-btn>
      </div>
    </v-card-actions>

    <!-- Project Details -->
    <v-card-text v-if="selectedCompanyUserStatus === 'employee'">
      <p class="font-weight-medium">
        Total Hours :
        <span v-if="project.minutes.hrs <= 9">0{{ project.minutes.hrs }}</span>
        <span v-else>{{ project.minutes.hrs }}</span>
        <span>:</span>
        <span v-if="project.minutes.mins <= 9"
          >0{{ project.minutes.mins }}</span
        >
        <span v-else>{{ project.minutes.mins }}</span>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";

export default {
  name: "Project",
  props: ["project"],
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;

      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.project) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    selected_company_data() {
      return this.$store.state.custom.selected_company;
    },
    base_url() {
      return baseURL.API_URL;
    },
  },
  mounted() {
    if (this.$route.params.project_id) {
      this.$nextTick(
        () =>
          setTimeout(() =>
            this.get_project_data(this.$route.params.project_id)
          ),
        500
      );
    }
  },
  methods: {
    get_project_data(project_id) {
      this.$root.$emit("single_project_selected", project_id);
    },
    /**
     * This function emits a Custom event
     * for update project modal
     */
    update_project_data(project_id) {
      this.$root.$emit("update_project_modal_edit_members", project_id);
    },
    /**
     * This function emits a Custom event
     * for archive project dialog
     */
    deleteProject(project) {
      this.$root.$emit("archive-project", project);
    },
  },
};
</script>

<style scoped>
.project-boxes {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, 0.1);
}

@media (min-width: 480px) and (max-width: 768px) {
  .img-fluid {
    width: 150px;
  }
}
</style>
